import React, { FC } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { useLocation, useHistory } from 'react-router-dom';
import { Box } from 'portal-commons';
import { getStatusIcon } from '../../../../../utils/statusIcon';
import { CampaignsData } from '../../../Campaigns/types';

interface Props {
  campaign: CampaignsData;
}
const CampaignListingRow: FC<Props> = (props) => {
  const { campaign } = props;
  const history = useHistory();
  const location = useLocation();

  return (
    <TableRow
      data-testid="campaignListingRow"
      className="link"
      onClick={() =>
        history.push({
          pathname: `/campaigns/${campaign.uid}`,
          state: {
            goBackPage: `${location.pathname}${
              location.search ? location.search : ''
            }`,
          },
        })
      }
    >
      <TableCell
        align="left"
        style={{ minWidth: 125 }}
        data-testid="campaignListingRowCampaignId"
      >
        <span>{campaign.uid}</span>
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 140, maxWidth: 220, overflowWrap: 'break-word' }}
        data-testid="campaignListingRowBrandName"
      >
        {campaign.brandName
          ? campaign.brandName
          : campaign.usecase === 'SOLE_PROPRIETOR'
          ? 'Redacted due to PII'
          : 'N/A'}
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 105 }}
        data-testid="campaignListingRowUsecase"
      >
        {campaign.usecase}
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 135 }}
        data-testid="campaignListingRowCreateDate"
      >
        {campaign.createDate}
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 140 }}
        data-testid="campaignListingRowTcrStatus"
      >
        <div className="tcr-status">
          {campaign.status ? (
            <span>
              {campaign.status.toLowerCase() === 'active'
                ? 'ACTIVE'
                : 'DEACTIVATED'}
            </span>
          ) : (
            <span>N/A</span>
          )}
        </div>
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 140, paddingTop: 0, paddingBottom: 0 }}
        data-testid="campaignListingRowMnoStatus"
      >
        <div className="mno-operational-status">
          {campaign.mnoStatus ? (
            <Box flexDirection="row" alignItems="center">
              <Box margin={{ right: 'xs' }}>
                {getStatusIcon(campaign.mnoStatus.toLowerCase())}
              </Box>
              <span>
                {campaign.mnoStatus.toLowerCase() === 'approved'
                  ? 'REGISTERED'
                  : campaign.mnoStatus}
              </span>
            </Box>
          ) : (
            <span>N/A</span>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};

export default CampaignListingRow;
