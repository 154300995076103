import { FC } from 'react';
import { Brand } from '../../types';
import { Grid } from '@material-ui/core';
import { CspDetailItem } from '../../../Csp/components';
import {
  getBrandRelation,
  getGovernmentEntity,
  getPoliticalCommitteeLocale,
} from '../../utils';

interface Props {
  brand: Brand;
}
const BrandDetailsSection: FC<Props> = ({ brand }: Props) => {
  const {
    website,
    stockSymbol,
    phone,
    vertical,
    stockExchange,
    optionalAttributes = {},
    brandRelationshipScore,
    altBusinessId,
    altBusinessIdType,
  } = brand || {};

  return (
    <Grid container>
      <Grid item xs={6}>
        <CspDetailItem
          title="Brand Relationship"
          value={getBrandRelation(brandRelationshipScore)}
          dataTestId="brandRelationshipField"
        />
        <CspDetailItem
          title="Website"
          value={website}
          dataTestId="websiteField"
        />
        <CspDetailItem
          title="Stock Symbol"
          value={stockSymbol}
          dataTestId="stockSymbolField"
        />
        <CspDetailItem
          title="Political Committee Locale"
          value={getPoliticalCommitteeLocale(optionalAttributes)}
          dataTestId="politicalCommitteeLocaleField"
        />
        <CspDetailItem
          title="Alt Business ID"
          value={altBusinessId}
          dataTestId="altBusinessIdField"
        />
      </Grid>
      <Grid item xs={6}>
        <CspDetailItem
          title="Support Phone Number"
          value={phone}
          dataTestId="supportPhoneNumberField"
        />
        <CspDetailItem
          title="Vertical"
          value={vertical}
          dataTestId="verticalField"
        />
        <CspDetailItem
          title="Stock Exchange"
          value={stockExchange}
          dataTestId="stockExchangeField"
        />
        <CspDetailItem
          title="Government Entity"
          value={getGovernmentEntity(optionalAttributes)}
          dataTestId="governmentEntityField"
        />
        <CspDetailItem
          title="Alt Business ID Type"
          value={altBusinessIdType}
          dataTestId="altBusinessIdTypeField"
        />
      </Grid>
    </Grid>
  );
};

export default BrandDetailsSection;
