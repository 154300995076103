import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import CspDetailItem from '../../../Csp/components/CspDetailItem';

interface Props {
  street: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}
const BrandAddressDetails: FC<Props> = ({
  street,
  city,
  state,
  postalCode,
  country,
}) => {
  return (
    <Grid container>
      <Grid item xs={6}>
        <CspDetailItem
          title="Address / Street"
          value={street}
          dataTestId="streetField"
        />
        <CspDetailItem title="City" value={city} dataTestId="cityField" />
        <CspDetailItem title="State" value={state} dataTestId="stateField" />
      </Grid>
      <Grid item xs={6}>
        <CspDetailItem
          title="Postal Code"
          value={postalCode}
          dataTestId="postalCodeField"
        />
        <CspDetailItem
          title="Country"
          value={country}
          dataTestId="countryField"
        />
      </Grid>
    </Grid>
  );
};

export default BrandAddressDetails;
