import { FilterType } from 'portal-commons';

export const FILTER_CONFIG = {
  type: {
    type: FilterType.Dropdown,
    label: 'Asset Type',
    placeholder: 'Select asset type',
    options: [
      {
        label: 'Logo',
        value: 'LOGO',
      },
      {
        label: 'Banner',
        value: 'BANNER',
      },
    ],
  },
};

export const TABLE_COLUMNS = [
  { id: 'name', label: 'ASSET NAME', sortable: false },
  { id: 'type', label: 'ASSET TYPE', sortable: true },
  { id: 'createDate', label: 'CREATION DATE', sortable: true },
  { id: 'expireDate', label: 'EXPIRATION DATE', sortable: false },
];
