import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import ScoreTooltip from './ScoreTooltip';
import { Box } from 'portal-commons';
import { VettingRecord } from '../../types';
import { displayVettingDate, displayVettingOutcome } from '../../utils';

interface Props {
  record: VettingRecord;
}

const VettingDetailsListingRow: React.FC<Props> = ({ record }) => {
  return (
    <TableRow>
      <TableCell style={{ width: '20%' }}>
        <span>{record.vettingClass}</span>
      </TableCell>
      <TableCell style={{ width: '20%' }}>{record.evpUid}</TableCell>
      <TableCell style={{ width: '15%' }}>
        {displayVettingDate(record)}
      </TableCell>
      <TableCell
        className="outcome"
        style={{
          width: '15%',
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        <Box alignItems="center" flexDirection="row">
          {displayVettingOutcome(record)}
          <ScoreTooltip data={record} />
        </Box>
      </TableCell>
      <TableCell style={{ width: '30%' }}>{record.vettingStatus}</TableCell>
    </TableRow>
  );
};

export default VettingDetailsListingRow;
