/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState, useEffect } from 'react';
import {
  Modal,
  Backdrop,
  TextField,
  makeStyles,
  Container,
  Typography,
} from '@material-ui/core';
import { Button, Box } from 'portal-commons';
import IntegrationReactSelect from '../../../../../shared_elements/ui_elements/IntegrationReactSelect';
import { getSuspensionCategoryOptions } from '../../apis';
import { isValidInput } from '../../../../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSquareMinus,
  faArrowRotateLeft,
} from '@fortawesome/pro-regular-svg-icons';
import { SelectItem } from '../../../../../utils/types';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 523,
    padding: 20,
    background: '#FFFFFF',
    boxSizing: 'border-box',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '26px',
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 500,
    color: '#646464',
    '& svg': {
      width: '20px',
      height: '20px',
    },
  },
  message: {
    width: '100%',
    marginBottom: '18px',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    color: '#49535D',
  },
  note: {
    width: '100%',
    marginBottom: '11px',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '12px',
    color: '#49535D',
  },
  cancelSuspend: {
    height: 30,
    padding: '0 22px',
    fontSize: 12,
    fontWeight: 400,
    color: '#D41C54',
    background: '#FFFFFF',
    border: '1px solid #D41C54',
    borderRadius: 200,
    cursor: 'pointer',
    outline: 'none',
  },
  suspend: {
    height: 30,
    padding: '0 18px',
    marginLeft: 10,
    fontSize: 12,
    fontWeight: 400,
    color: '#FFFFFF',
    background: '#D41C54',
    border: 'none',
    borderRadius: 200,
    cursor: 'pointer',
    outline: 'none',
    '&:disabled': {
      opacity: 0.4,
      cursor: 'not-allowed',
    },
  },
  cancelLift: {
    height: 30,
    padding: '0 22px',
    fontSize: 12,
    fontWeight: 400,
    color: '#0091B3',
    background: '#FFFFFF',
    border: '1px solid #0091B3',
    borderRadius: 200,
    cursor: 'pointer',
    outline: 'none',
  },
  lift: {
    height: 30,
    padding: '0 34px',
    marginLeft: 10,
    fontSize: 12,
    fontWeight: 400,
    color: '#FFFFFF',
    background: '#0091B3',
    border: 'none',
    borderRadius: 200,
    cursor: 'pointer',
    outline: 'none',
    '&:disabled': {
      opacity: 0.4,
      cursor: 'not-allowed',
    },
  },
});

interface Props {
  open: boolean;
  type: string;
  cspUid: string;
  brandName: string;
  onCancel?: () => void;
  onSubmit?: (reason: string, suspensionCategory: string) => void;
}
const BrandActionModal: FC<Props> = ({
  open,
  type,
  cspUid,
  brandName,
  onCancel,
  onSubmit,
}) => {
  const [reason, setReason] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [categoryOptions, setCategoryOptions] = useState<SelectItem[]>([
    { label: 'Select Reason', value: '' },
  ]);
  const [suspensionCategory, setSuspensionCategory] = useState<string>('');
  const classes = useStyles();

  const handleReasonChange = (value: string) => {
    setReason(value);
    setError('');
  };

  const handleReasonBlur = (value: string) => {
    setError(
      value.trim() === ''
        ? 'Please enter details'
        : isValidInput(value)
        ? ''
        : 'Invalid input'
    );
  };

  const handleCancel = () => {
    setReason('');
    setError('');
    setSuspensionCategory('');
    onCancel && onCancel();
  };

  const handleSubmit = () => {
    onSubmit && onSubmit(reason, suspensionCategory);
    handleCancel();
  };

  const getAllSuspensionCategories = async () => {
    const options = await getSuspensionCategoryOptions();
    setCategoryOptions((prevState: any) => {
      return [...prevState, ...options];
    });
  };

  useEffect(() => {
    getAllSuspensionCategories();
  }, []);

  return (
    <Modal
      className={classes.modal}
      BackdropComponent={Backdrop}
      open={open}
      onClose={(e: object, closeReason: string) =>
        closeReason !== 'backdropClick' && handleCancel()
      }
      data-testid="brandActionModal"
    >
      <Container classes={{ root: classes.container }}>
        <Typography
          classes={{ h3: classes.title }}
          align="center"
          variant="h3"
          data-testid="modalTitle"
        >
          {type === 'suspend' ? (
            <>
              <Box margin={{ right: 'xs' }}>
                <FontAwesomeIcon icon={faSquareMinus} size="lg" />
              </Box>
              Suspend All Campaigns
            </>
          ) : (
            <>
              <Box margin={{ right: 'xs' }}>
                <FontAwesomeIcon icon={faArrowRotateLeft} />
              </Box>
              Lift All Suspensions
            </>
          )}
        </Typography>
        <Typography classes={{ h4: classes.message }} variant="h4">
          {type === 'suspend'
            ? 'Suspend all Registered Campaigns under this Brand.'
            : 'Lift Suspension for all Suspended Campaigns under this Brand.'}
        </Typography>
        <Typography classes={{ h5: classes.note }} variant="h5">
          * Indicates a Required Field
        </Typography>
        <div className="form-group-field" style={{ width: '100%' }}>
          <TextField
            disabled
            fullWidth
            margin="normal"
            label="CSP"
            value={cspUid}
          />
        </div>
        <div className="form-group-field" style={{ width: '100%' }}>
          <TextField
            disabled
            fullWidth
            margin="normal"
            label="Brand Name"
            value={brandName}
          />
        </div>
        {type === 'suspend' && (
          <div className="form-group-field" style={{ width: '100%' }}>
            <IntegrationReactSelect
              options={categoryOptions}
              label="Campaign Suspension Category"
              placeholder="Select Reason"
              value={categoryOptions.find(
                (option: SelectItem) => option.value === suspensionCategory
              )}
              testid="suspensionCategorySelect"
              onChange={(option: SelectItem) =>
                setSuspensionCategory(option.value)
              }
              keyname="suspensionCategory"
            />
          </div>
        )}
        <div className="form-group-field" style={{ width: '100%' }}>
          <TextField
            inputProps={{
              autoFocus: true,
              maxLength: 3000,
              'data-testid': 'reasonField',
            }}
            required
            error={!!error}
            autoFocus
            multiline
            fullWidth
            margin="normal"
            label="Details"
            autoComplete="off"
            value={reason}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleReasonChange(e.target.value)
            }
            onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
              handleReasonBlur(e.target.value)
            }
          />
          {error && <h6 className="error-msg">{error}</h6>}
        </div>
        <div style={{ marginTop: 15, marginBottom: 10 }}>
          {type === 'suspend' && (
            <div className="flex">
              <Button
                data-testid="suspendCancelButton"
                portal="mno"
                shape="rounded"
                variant="outline"
                className="m-r-s"
                color="primary"
                onClick={handleCancel}
              >
                <span>Cancel</span>
              </Button>

              <Button
                data-testid="suspendSubmitButton"
                portal="mno"
                disabled={!!error}
                shape="rounded"
                color="primary"
                onClick={handleSubmit}
              >
                <span>Suspend</span>
              </Button>
            </div>
          )}
          {type === 'lift' && (
            <div className="flex">
              <Button
                data-testid="cancelLiftAllCampaignButton"
                portal="mno"
                shape="rounded"
                variant="outline"
                color="secondary"
                onClick={handleCancel}
                className="m-r-s"
              >
                <span>Cancel</span>
              </Button>

              <Button
                data-testid="liftAllCampaignButton"
                portal="mno"
                disabled={!!error}
                shape="rounded"
                variant="standard"
                color="secondary"
                onClick={handleSubmit}
              >
                <span>Lift</span>
              </Button>
            </div>
          )}
        </div>
      </Container>
    </Modal>
  );
};

export default BrandActionModal;
