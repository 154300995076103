import React, { FC } from 'react';
import { Grid, GridSize } from '@material-ui/core';

interface Props {
  title: string;
  value?: string | null;
  xs?: number;
  link?: string;
  dataTestId?: string;
}
const CspDetailItem: FC<Props> = (props: Props) => {
  const defaultXs = props.xs ? props.xs : 4;
  const leftXs: GridSize = defaultXs as GridSize;
  const rightXs: GridSize = (12 - defaultXs) as GridSize;
  return (
    <Grid
      container
      spacing={3}
      className="detail-item"
      data-testid={props.dataTestId ? props.dataTestId : 'cspDetailItem'}
    >
      <Grid
        item
        xs={leftXs}
        data-testid={props.title && props.title.replace(/\s/g, '')}
      >
        <h3 className="paragraph">{props.title}:</h3>
      </Grid>
      <Grid
        item
        xs={rightXs}
        data-testid={props.value && props.value.replace(/\s/g, '')}
      >
        {props.link ? (
          <h3 className="paragraph">
            <a href={props.link}>
              <span style={{ color: '#00698F', textDecoration: 'underline' }}>
                {props.value ? props.value : 'N/A'}
              </span>
            </a>
          </h3>
        ) : (
          <h3 className="paragraph">
            <span>{props.value ? props.value : 'N/A'}</span>
          </h3>
        )}
      </Grid>
    </Grid>
  );
};

export default CspDetailItem;
