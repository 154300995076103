import React, { FC } from 'react';
import CspDetailItem from '../../../Csp/components/CspDetailItem';
import { Grid } from '@material-ui/core';
import { Csp } from '../../../Csp/types';

const BrandCspDetails: FC<{ cspInfo: Csp }> = ({ cspInfo }) => {
  return (
    <Grid container>
      <Grid item xs={6}>
        <CspDetailItem
          title="Legal Company Name"
          value={cspInfo.companyName}
          dataTestId="legalCompanyNameField"
        />
        <CspDetailItem
          title="Email Address"
          value={cspInfo.email}
          dataTestId="emailAddressField"
        />
      </Grid>
      <Grid item xs={6}>
        <CspDetailItem
          title="CSP ID"
          value={cspInfo.uid}
          dataTestId="cspIdField"
        />
        <CspDetailItem
          title="Phone Number"
          value={cspInfo.phone}
          dataTestId="phoneNumberField"
        />
      </Grid>
    </Grid>
  );
};

export default BrandCspDetails;
