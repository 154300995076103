import React, { FC } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { useLocation, useHistory } from 'react-router-dom';
import { ENTITY_TYPES } from '../../../../../constants';
import { Brand } from '../../types';

interface Props {
  brand: Brand;
}
const BrandListingRow: FC<Props> = (props: Props) => {
  const { brand } = props;
  const history = useHistory();
  const location = useLocation();
  return (
    <TableRow
      className="link"
      data-testid="brandingListingRow"
      onClick={() =>
        history.push({
          pathname: `/brands/${brand.uid}`,
          state: {
            goBackPage: `${location.pathname}${
              location.search ? location.search : ''
            }`,
          },
        })
      }
    >
      <TableCell
        align="left"
        style={{ minWidth: 90 }}
        data-testid="brandingListingRowUid"
      >
        <span>{brand.uid}</span>
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 140, maxWidth: 220 }}
        data-testid="brandingListingRowDisplayName"
      >
        {brand.displayName
          ? brand.displayName
          : brand.entityType === 'SOLE_PROPRIETOR'
          ? 'Redacted due to PII'
          : 'N/A'}
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 140, maxWidth: 220 }}
        data-testid="brandingListingRowCspDisplayName"
      >
        {brand.csp.displayName ? brand.csp.displayName : 'N/A'}
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 140 }}
        data-testid="brandingListingRowEntityType"
      >
        {ENTITY_TYPES.find((entity) => entity.value === brand.entityType)
          ?.label ||
          brand.entityType ||
          'N/A'}
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 140, maxWidth: 220 }}
        data-testid="brandingListingRowUniversalEin"
      >
        {brand.universalEin ? brand.universalEin : 'N/A'}
      </TableCell>
      <TableCell
        align="left"
        data-testid="brandingListingRowActiveCampaignCount"
      >
        {brand.activeCampaignCount != null ? brand.activeCampaignCount : '0'}
      </TableCell>
    </TableRow>
  );
};

export default BrandListingRow;
