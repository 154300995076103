import React from 'react';
import {
  faSquareMinus,
  faSquareCheck,
  faSquareXmark,
  faSquareEllipsis,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const STATUS_ICON_CONFIG = {
  suspended: {
    icon: faSquareMinus,
    style: { color: 'D41C54' },
  },
  registered: {
    icon: faSquareCheck,
    style: { color: '009B7C' },
  },
  approved: {
    icon: faSquareCheck,
    style: { color: '009B7C' },
  },
  rejected: {
    icon: faSquareXmark,
    style: { color: 'black' },
  },
  review: {
    icon: faSquareEllipsis,
    style: { color: '00698F' },
  },
};

const getStatusIcon = (status) => {
  const config = STATUS_ICON_CONFIG[status.toLowerCase()];
  if (config) {
    return <FontAwesomeIcon size="xl" {...config} />;
  }
  return undefined;
};

export { getStatusIcon };
