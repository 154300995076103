/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { SelectItem } from '../../utils/types';

interface Props {
  options: SelectItem[];
  value?: SelectItem;
  placeholder: string;
  label: string;
  onChange: (value: SelectItem, keyname: string) => void;
  keyname: string;
  required?: boolean;
  size?: 'small' | 'medium';
  testid?: string;
  [key: string]: any;
}
const IntegrationReactSelect: React.FC<Props> = (props: Props) => {
  function handleChangeOption(selectedOption: SelectItem) {
    return props.onChange(selectedOption, props.keyname);
  }
  return (
    <Autocomplete
      // {...defaultProps}
      autoFocus
      options={props.options}
      getOptionLabel={(option: SelectItem | string) =>
        typeof option === 'string' ? option : option.label
      }
      placeholder={props.label}
      value={props.value}
      disableClearable
      onChange={(e: React.ChangeEvent<object>, value: SelectItem) =>
        handleChangeOption(value)
      }
      id="integrationReactSelect"
      data-testid={props.testid ?? 'integrationReactSelect'}
      renderInput={(params: any) => (
        <TextField
          {...params}
          autoComplete="new-password"
          label={props.value ? props.value.label : ''}
          placeholder={props.placeholder ?? props.label}
          required={props.required ?? false}
          size={props.size ?? 'medium'}
          margin="normal"
          fullWidth
        />
      )}
    />
  );
};

export default IntegrationReactSelect;
