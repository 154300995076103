import React, { useState } from 'react';
import { Tooltip, ClickAwayListener } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { toastFlashMessage } from '../../../../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-regular-svg-icons';
import { VettingRecord } from '../../types';

const CustomTooltip = withStyles(() => ({
  arrow: {
    color: '#00797C',
  },
  tooltip: {
    background: '#FFFFFF',
    color: '#0091B3',
    fontSize: 14,
    borderRadius: '0',
    maxWidth: 526,
    width: 526,
    maxHeight: 355,
    fontFamily: 'Roboto',
    fontWeight: 400,
    padding: 0,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
}))(Tooltip);

interface Props {
  data: VettingRecord;
}

const ScoreTooltip: React.FC<Props> = ({ data }) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const getCopyTextTemplate = (text: string | undefined, keyName: string) => {
    if (!text) {
      return undefined;
    }
    return (
      <p
        className="p-l-xs pointer copy"
        style={{ marginRight: 17 }}
        onClick={() => {
          navigator.clipboard.writeText(text);
          toastFlashMessage(
            `${keyName} has been copied to your clipboard`,
            'success'
          );
        }}
      >
        copy
      </p>
    );
  };

  const remapStringReasonsToUITemplate = (stringReason: string) => {
    const array = JSON.parse(stringReason);
    return array.map((reason: string, index: number) => {
      return <li key={`reasons-${index}`}>{reason}</li>;
    });
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <CustomTooltip
        PopperProps={{
          disablePortal: true,
          popperOptions: {
            positionFixed: true,
            modifiers: {
              preventOverflow: {
                enabled: true,
                boundariesElement: 'window',
              },
            },
          },
        }}
        open={open}
        onClose={handleTooltipClose}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        interactive
        placement={'bottom-end'}
        id="tooltip"
        title={
          <div className="vetting-details-tooltip">
            <h6 className="paragraph title">
              Please see the vetting details below:
            </h6>
            {data && data.reasons && (
              <div className="reasons-container" style={{ overflowY: 'unset' }}>
                <ul
                  className="vetting-detail-list-tooltip"
                  data-testid="reasons"
                >
                  {data && data.reasons
                    ? remapStringReasonsToUITemplate(data.reasons)
                    : null}
                </ul>
              </div>
            )}
            {data.vettingClass === 'POLITICAL' ? (
              data.evpUid === 'AEGIS' ? (
                <div className="reasons-container" style={{ flex: 1 }}>
                  <ul
                    className="vetting-detail-list-tooltip"
                    style={{ maxHeight: 'unset' }}
                    data-testid="politicalReasons"
                  >
                    {data.additionalRequestPayload &&
                      Object.entries(
                        JSON.parse(data.additionalRequestPayload)
                      ).map(([key, value], index) => (
                        <li className="no-style" key={`reasons-${index}`}>
                          <span>{key}: </span> {value}
                        </li>
                      ))}
                  </ul>
                </div>
              ) : (
                <div className="reasons-container" style={{ flex: 1 }}>
                  <ul
                    className="vetting-detail-list-tooltip"
                    style={{ maxHeight: 'unset' }}
                    data-testid="politicalReasons"
                  >
                    {data.fullReport &&
                      Object.entries(JSON.parse(data.fullReport)).map(
                        ([key, value], index) => (
                          <li className="no-style" key={`reasons-${index}`}>
                            <span>{key}: </span> {` ${value}`}
                          </li>
                        )
                      )}
                  </ul>
                </div>
              )
            ) : null}
            <div className="copy-paste-container">
              <hr />
              {data.vettingClass === 'POLITICAL' ? (
                data.evpUid === 'AEGIS' ? (
                  <div className="flex flex-1 space-between text-truncate">
                    <p className="flex-2 text-truncate">
                      <span>Vetting ID: </span> {data.vettingId || 'N/A'}
                    </p>
                    {getCopyTextTemplate(data.vettingId, 'Vetting ID')}
                  </div>
                ) : (
                  <div className="flex space-between">
                    <div className="flex flex-1 space-between text-truncate">
                      <p className="flex-2 text-truncate">
                        <span>Vetting ID: </span> {data.vettingId || 'N/A'}
                      </p>
                      {getCopyTextTemplate(data.vettingId, 'Vetting ID')}
                    </div>
                    <div className="flex flex-1 space-between text-truncate">
                      <p className="flex-2 text-truncate">
                        <span>Vetting Token: </span>{' '}
                        {data.vettingToken || 'N/A'}
                      </p>
                      {getCopyTextTemplate(data.vettingToken, 'Vetting Token')}
                    </div>
                  </div>
                )
              ) : data.evpUid === 'WMC' ? (
                <div className="flex space-between">
                  <div className="flex flex-1 space-between text-truncate">
                    <p className="flex-2 text-truncate">
                      <span>Vetting ID/Transaction ID: </span>{' '}
                      {data.vettingId || 'N/A'}
                    </p>
                    {getCopyTextTemplate(
                      data.vettingId,
                      'Vetting ID/Transaction ID'
                    )}
                  </div>
                  <div className="flex flex-1 space-between text-truncate">
                    <p className="flex-2 text-truncate">
                      <span>Vetting Token: </span>{' '}
                      {(data.fullReport &&
                        JSON.parse(data.fullReport).vettingToken) ||
                        'N/A'}
                    </p>
                    {data.fullReport &&
                      getCopyTextTemplate(
                        JSON.parse(data.fullReport).vettingToken,
                        'Vetting Token'
                      )}
                  </div>
                </div>
              ) : (
                <div className="flex space-between">
                  <div className="flex flex-1 space-between text-truncate">
                    <p className="flex-2 text-truncate">
                      <span>Vetting ID: </span> {data.vettingId || 'N/A'}
                    </p>
                    {getCopyTextTemplate(data.vettingId, 'Vetting ID')}
                  </div>
                  <div className="flex flex-1 space-between text-truncate">
                    <p className="flex-2 text-truncate">
                      <span>Vetting Token: </span> {data.vettingToken || 'N/A'}
                    </p>
                    {getCopyTextTemplate(data.vettingToken, 'Vetting Token')}
                  </div>
                </div>
              )}
            </div>
          </div>
        }
      >
        <span
          onClick={handleTooltipOpen}
          className={`${open ? 'active' : ''} view-icon`}
          data-testid="viewIcon"
        >
          <FontAwesomeIcon
            icon={faEye}
            size="lg"
            style={{ padding: '1px 2px' }}
          />
        </span>
      </CustomTooltip>
    </ClickAwayListener>
  );
};

export default ScoreTooltip;
