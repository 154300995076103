import React, { FC } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import {
  US_DATE_FORMAT,
  convertTimeWithTimezone,
} from '../../../../../utils/time';
import { Box } from 'portal-commons';
import { getStatusIcon } from '../../../../../utils/statusIcon';
import { Campaign } from '../../../Campaigns/types';

interface Props {
  campaign: Campaign;
}
const SuspensionCampaignRow: FC<Props> = (props: Props) => {
  const { campaign } = props;
  const history = useHistory();
  const location = useLocation();

  return (
    <TableRow
      data-testid="suspensionCampaignRow"
      className="link"
      onClick={() =>
        history.push({
          pathname: `/campaigns/${campaign.uid}`,
          state: {
            goBackPage: `${location.pathname}${
              location.search ? location.search : ''
            }`,
          },
        })
      }
    >
      <TableCell
        align="left"
        style={{ minWidth: 125 }}
        data-testid="suspensionCampaignRowUid"
      >
        <span>{campaign.uid}</span>
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 105 }}
        data-testid="suspensionCampaignRowUsecase"
      >
        {campaign.usecase}
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 135 }}
        data-testid="suspensionCampaignRowCreateDate"
      >
        {convertTimeWithTimezone(
          campaign.createDate,
          undefined,
          US_DATE_FORMAT
        )}
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 140, paddingTop: 0, paddingBottom: 0 }}
        data-testid="suspensionCampaignRowOperationStatus"
      >
        <div className="mno-operational-status">
          {campaign.operationStatus ? (
            <Box flexDirection="row" alignItems="center">
              <Box margin={{ right: 'xs' }}>
                {getStatusIcon(campaign.operationStatus)}
              </Box>
              <span>{campaign.operationStatus}</span>
            </Box>
          ) : (
            <span>N/A</span>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};

export default SuspensionCampaignRow;
