import React, { FC, useEffect, useState } from 'react';
import { Box, Modal, TextField, makeStyles } from '@material-ui/core';
import { MAX_TEXT_LENGTH } from '../../../../../constants';
import IntegrationReactSelect from '../../../../../shared_elements/ui_elements/IntegrationReactSelect';
import { Button, Box as BoxCommons } from 'portal-commons';
import { getSuspensionCategoryOptions } from '../../apis';
import { isValidInput } from '../../../../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareMinus } from '@fortawesome/pro-regular-svg-icons';
import { Brand } from '../../types';
import { SelectItem } from '../../../../../utils/types';

export const SuspensionModalStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '525px',
    padding: '28px',
    marginLeft: '100px',
    color: '#49535D',
    backgroundColor: '#FFFFFF',
    borderRadius: '5px',
    boxSizing: 'border-box',
  },
  header: {
    color: '#646464',
    fontSize: '16px',
    lineHeight: 1.175,
    fontWeight: 500,
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      marginTop: '-1px',
      marginRight: '5px',
    },
  },
  paragraph: {
    fontSize: '14px',
    lineHeight: 1.175,
    marginBottom: '20px',
    '& span': {
      fontWeight: 800,
    },
  },
  form: {
    '& .hint': {
      fontSize: '12px',
      lineHeight: 1.175,
      marginBottom: '10px',
    },
    '& .MuiTextField-root': {
      marginBottom: '15px',
      color: '#68737A',
      '& .MuiFilledInput-root': {
        fontSize: '14px',
        lineHeight: 1.175,
        backgroundColor: '#F6F7F7',
      },
      '& .MuiInputBase-root.Mui-disabled': {
        color: '#49535D',
      },
      '& .MuiFilledInput-underline:after': {
        borderColor: '#00698F',
      },
      '& .MuiFilledInput-underline.Mui-error:after': {
        borderColor: '#AA0000',
      },
      '& .MuiFormLabel-asterisk.Mui-error': {
        color: '#AA0000',
      },
    },
    '& .MuiInputLabel-root': {
      fontSize: '14px',
      '&.Mui-focused': {
        color: '#00698F',
      },
      '&.Mui-error': {
        color: '#AA0000',
      },
    },
    '& .MuiFilledInput-input': {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
    '& .MuiInputLabel-filled.MuiInputLabel-marginDense': {
      transform: 'translate(8px, 22px) scale(1)',
    },
    '& .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense': {
      transform: 'translate(8px, 7px) scale(.85)',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: '#AA0000',
      marginTop: 0,
      marginLeft: '8px',
    },
    '& .MuiAutocomplete-root': {
      marginTop: '-15px',
      '& .MuiTextField-root': {
        paddingTop: '3px',
        backgroundColor: '#F6F7F7',
      },
      '& .MuiInputLabel-shrink': {
        transform: 'translate(8px, 7px) scale(.85)',
      },
      '& .MuiInput-root': {
        paddingLeft: '8px',
        fontSize: '14px',
      },
    },
  },
  actionBtns: {
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& button + button': {
      marginLeft: '15px',
    },
  },
}));

interface SubmitPayload {
  suspensionCategory: string;
  explanation: string;
}
interface Props {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (payload: SubmitPayload) => void;
  brandInfo: Brand;
  totalCampaigns: number;
  brandSuspended: boolean | null;
}
const BrandSuspensionModal: FC<Props> = (props: Props) => {
  const {
    open,
    handleClose,
    handleSubmit,
    brandInfo,
    totalCampaigns,
    brandSuspended,
  } = props;

  const classes = SuspensionModalStyles();
  const butColor = brandSuspended ? 'secondary' : 'primary';
  const [selectOptions, setSelectOptions] = useState<SelectItem[]>([
    {
      label: 'Select Reason',
      value: '',
    },
  ]);
  const [suspendReason, setSuspendReason] = useState<string>('');
  const [explanation, setExplanation] = useState<string>('');
  const [explanationError, setExplanationError] = useState<string>('');

  const brandName =
    brandInfo.entityType &&
    brandInfo.entityType.toLowerCase() === 'sole_proprietor'
      ? 'Redacted due to PII'
      : brandInfo.displayName
      ? brandInfo.displayName
      : 'N/A';

  const getAllSuspensionCategories = async () => {
    const options = await getSuspensionCategoryOptions();
    setSelectOptions((prevState) => {
      return [...prevState, ...options];
    });
  };

  const validate = () => {
    if (explanation.length > MAX_TEXT_LENGTH) {
      setExplanationError(`Maximum ${MAX_TEXT_LENGTH} chars`);
      return false;
    } else if (!isValidInput(explanation)) {
      setExplanationError('Invalid input');
      return false;
    }

    setExplanationError('');
    return true;
  };

  const handleDialogClose = () => {
    setSuspendReason('');
    setExplanation('');
    setExplanationError('');
    handleClose();
  };
  const handleFormSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (validate()) {
      const data: SubmitPayload = {
        suspensionCategory: suspendReason,
        explanation,
      };
      handleSubmit(data);
      handleDialogClose();
    }
  };
  const handleExplanationUpdate = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    const { value } = event.target;
    setExplanation(value);
    setExplanationError('');
  };
  const handleExplanationBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    event.preventDefault();
    validate();
  };

  useEffect(() => {
    getAllSuspensionCategories();
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleDialogClose}
      aria-labelledby="Brand Suspension Modal"
      aria-describedby="Suspend or Lift Suspension Campaigns"
      data-testid="brandSuspensionModal"
    >
      <Box className={classes.container}>
        <h2 className={classes.header} data-testid="brandSuspensionModalHeader">
          <BoxCommons flexDirection="row" alignItems="center">
            <BoxCommons margin={{ right: 'xs' }}>
              <FontAwesomeIcon icon={faSquareMinus} size="lg" />
            </BoxCommons>
            <span>
              {brandSuspended ? 'Lift Brand Suspension' : 'Brand Suspension'}
            </span>
          </BoxCommons>
        </h2>
        <p className={classes.paragraph} data-testid="brandSuspensionModalText">
          {brandSuspended ? (
            <React.Fragment>
              Lifting this brands suspension un-suspends{' '}
              <span>{totalCampaigns} campaigns</span> created by this brand and
              allows new campaigns to qualify for your network.
            </React.Fragment>
          ) : (
            <React.Fragment>
              Suspending this brand suspends{' '}
              <span>{totalCampaigns} campaigns</span> created by this brand and
              prevents new campaigns from qualifying for your network.
            </React.Fragment>
          )}
        </p>
        <form className={classes.form}>
          <p className="hint">* Indicates a Required Field</p>
          <TextField
            variant="filled"
            label="Brand ID"
            fullWidth
            size="small"
            disabled
            value={brandInfo.uid}
          />
          <TextField
            variant="filled"
            label="Brand Name"
            fullWidth
            size="small"
            disabled
            value={brandName}
          />
          {!brandSuspended && (
            <IntegrationReactSelect
              options={selectOptions}
              required={true}
              size="small"
              label="Suspension Category"
              placeholder="Select Reason"
              value={selectOptions.find(
                (option: SelectItem) => option.value === suspendReason
              )}
              onChange={(option: SelectItem) => setSuspendReason(option.value)}
              keyname="suspendReason"
              testid="suspensionCategorySelect"
            />
          )}
          <TextField
            error={!!explanationError}
            variant="filled"
            label="Explanation"
            size="small"
            inputProps={{ 'data-testid': 'reasonField' }}
            required
            fullWidth
            onChange={handleExplanationUpdate}
            onBlur={handleExplanationBlur}
            helperText={explanationError}
          />
        </form>
        <div className={classes.actionBtns}>
          <Button
            variant="outline"
            color={butColor}
            onClick={handleDialogClose}
            data-testid="cancelSuspensionModalButton"
          >
            Cancel
          </Button>
          <Button
            disabled={
              brandSuspended
                ? !!explanationError || !explanation
                : !!explanationError || !explanation || !suspendReason
            }
            color={butColor}
            onClick={handleFormSubmit}
            data-testid={
              brandSuspended ? 'confirmUnsuspendButton' : 'confirmSuspendButton'
            }
          >
            {brandSuspended ? 'Unsuspend' : 'Suspend'}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default BrandSuspensionModal;
