import { BRAND_RELATIONSHIP_SCORE, ENTITY_TYPES } from '../../../constants';
import { convertTimeWithTimezone } from '../../../utils/time';
import { BrandEntityType, VettingRecord } from './types';

export const getEntityType = (entityType: BrandEntityType) => {
  return (
    ENTITY_TYPES.find((entity) => entity.value === entityType)?.label ||
    entityType ||
    'N/A'
  );
};

export const getR3kStatus = (optionalAttributes: Record<string, unknown>) => {
  return optionalAttributes && 'russell3000' in optionalAttributes
    ? 'True'
    : 'False';
};

export const getTaxExemptStatus = (
  optionalAttributes: Record<string, unknown>
) => {
  if (optionalAttributes) {
    if ('section527' in optionalAttributes) {
      return '527';
    }
    if ('taxExemptStatus' in optionalAttributes) {
      return optionalAttributes.taxExemptStatus;
    }
  }
  return 'N/A';
};

export const getGovernmentEntity = (
  optionalAttributes: Record<string, unknown>
) => {
  return optionalAttributes?.governmentEntity ? 'True' : 'False';
};

export const getPoliticalCommitteeLocale = (
  optionalAttributes: Record<string, unknown>
) => {
  if (optionalAttributes && 'politicalCommitteeLocale' in optionalAttributes) {
    return optionalAttributes.politicalCommitteeLocale as string;
  }
  return 'N/A';
};

export const getBrandRelation = (brandRelationshipScore: number) => {
  const relationship = BRAND_RELATIONSHIP_SCORE.find(
    (item) => item.value === brandRelationshipScore
  );
  return relationship?.label || 'N/A';
};

const lowerCase = (str: string) => str.toLowerCase();

export const displayVettingDate = (record: VettingRecord) => {
  const { vettingStatus, vettedDate } = record;
  switch (lowerCase(vettingStatus)) {
    case 'active':
      if (vettedDate) {
        return convertTimeWithTimezone(vettedDate);
      } else {
        return '--';
      }
    case 'expired':
      return 'Expired';
    case 'contact_vendor':
      return 'Contact Vendor';
    default:
      return 'N/A';
  }
};

export const displayVettingOutcome = (record: VettingRecord) => {
  const { vettingStatus, score, evpUid } = record;
  switch (lowerCase(vettingStatus)) {
    case 'active':
      if (score) {
        return `${score}`;
      } else if (evpUid === 'CV') {
        return 'Verified';
      } else {
        return '--';
      }
    case 'expired':
      return 'Expired';
    case 'contact_vendor':
      return 'Contact Vendor';
    default:
      return 'N/A';
  }
};
