import { FC, useRef } from 'react';
import { useState, useMemo } from 'react';
import { capitalize } from 'lodash-es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import {
  useFilters,
  useTable,
  TableFilter,
  Table,
  BoxV2 as Box,
  AttachmentPreview,
} from 'portal-commons';

import { FILTER_CONFIG, TABLE_COLUMNS } from './constants';
import { getBrandAssets, downloadAsset } from '../../apis';
import { BrandAsset } from '../../types';
import FilterToggleButton from '../../../../../shared_elements/ui_elements/FilterToggleButton';
import { convertTimeWithTimezone } from '../../../../../utils/time';
import { sort } from './helper';
import { toastFlashMessage } from '../../../../../utils';

interface BrandAssetsProps {
  brandId: string;
}

const PAGE_SIZE = 10;

const BrandAssets: FC<BrandAssetsProps> = ({ brandId }) => {
  const [isFilterExpanded, setIsFilterExpanded] = useState(false);
  const [brandAssets, setBrandAssets] = useState<BrandAsset[]>([]);
  const cachedAssetsRef = useRef<{ [id: string]: File }>({});
  const [previewingAsset, setPreviewingAsset] = useState<BrandAsset | null>(
    null
  );
  const [downloadingUUid, setDownloadingUUid] = useState<string | null>(null);
  const lastClickedAssetRef = useRef<string>();

  const filter = useFilters({ configs: FILTER_CONFIG, inMemory: true });
  const table = useTable<BrandAsset>(
    async (additionalFilters) => {
      let assets: BrandAsset[] =
        brandAssets.length === 0
          ? await getBrandAssets(brandId, { verificationStatus: 'VERIFIED' })
          : [...brandAssets];

      if (brandAssets.length === 0) {
        setBrandAssets(assets);
      }

      // Apply filter by asset type if specified.
      if (filter.appliedFilters.type) {
        assets = assets.filter(
          (asset) => asset.type === filter.appliedFilters.type
        );
      }

      // Sort assets if a sort field is provided.
      if (additionalFilters?.sortField) {
        const { sortField, ascendingOrder } = additionalFilters;
        assets = sort<BrandAsset>(
          assets,
          sortField as keyof BrandAsset,
          ascendingOrder
        );
      }

      // Paginate the data.
      const page = additionalFilters?.page || 1;
      const start = (page - 1) * PAGE_SIZE;
      const end = start + PAGE_SIZE;

      return {
        data: assets.slice(start, end),
        rowsPerPage: PAGE_SIZE,
        total: assets.length,
        page,
      };
    },
    {
      deps: [filter.appliedFilters, brandId],
      defaultFilter: {
        sortField: 'createDate',
        ascendingOrder: false,
      },
      inMemory: true,
    }
  );

  const handlePreview = async (asset: BrandAsset) => {
    const { attachmentUuid = '' } = asset;
    let file: File | undefined = cachedAssetsRef.current[attachmentUuid];
    lastClickedAssetRef.current = attachmentUuid;
    setPreviewingAsset(null);

    if (file) {
      setDownloadingUUid(null);
      setPreviewingAsset(asset);
    } else {
      setDownloadingUUid(attachmentUuid);
      file = await downloadAsset(asset);
      setDownloadingUUid(null);
      if (file) {
        cachedAssetsRef.current[attachmentUuid] = file;
      }
    }

    if (lastClickedAssetRef.current === attachmentUuid) {
      setPreviewingAsset(asset);
      !file && toastFlashMessage('Failed to download the asset', 'error');
    }
  };

  const tableData = useMemo(() => {
    return table.tableData.map((asset) => ({
      ...asset,
      name: (
        <Box
          role="button"
          sx={{
            color: 't.blue700',
            fontSize: 'H300',
            fontWeight: 'semiBold',
            cursor: 'pointer',
          }}
          onClick={(e) => {
            e.preventDefault();
            handlePreview(asset);
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            {asset.name}
            {downloadingUUid === asset.attachmentUuid && (
              <FontAwesomeIcon icon={faSpinner} spin />
            )}
          </Box>
        </Box>
      ),

      type: capitalize(asset.type),
      createDate: convertTimeWithTimezone(asset.createDate) || 'N/A',
      expireDate: convertTimeWithTimezone(asset.validUntil) || 'N/A',
    }));
  }, [table.tableData, downloadingUUid]);

  return (
    <>
      <Box sx={{ my: '8px' }}>
        <FilterToggleButton
          size="small"
          visible={isFilterExpanded}
          onClick={() => {
            setIsFilterExpanded(!isFilterExpanded);
          }}
        />
        {isFilterExpanded && (
          <Box sx={{ mt: '8px' }}>
            <TableFilter
              configs={FILTER_CONFIG}
              candidateValues={filter.candidateFilters}
              appliedValues={filter.appliedFilters}
              onAppliedValuesChange={filter.handleApply}
              onCandidateValuesChange={filter.handleEdit}
              data-testid="brandAssetsFilter"
            />
          </Box>
        )}
      </Box>
      {previewingAsset &&
        cachedAssetsRef.current[previewingAsset.attachmentUuid] && (
          <AttachmentPreview
            file={cachedAssetsRef.current[previewingAsset.attachmentUuid]}
            onClose={() => setPreviewingAsset(null)}
            mimeType="image/jpeg"
          />
        )}
      <Table
        testId="brandAssetsTable"
        loading={table.loading}
        disableHover
        headRows={TABLE_COLUMNS}
        data={tableData}
        filter={table.filter}
        pagination={table.pagination}
        handleChangePage={table.handleChangePage}
        createSortHandler={table.handleSorting}
        emptyState="no assets to view"
        emptyBlockHeight="180px"
      />
    </>
  );
};

export default BrandAssets;
